import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import { Link } from "gatsby"

import * as actions from "../state/actions/keymans"
import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import Banner from "../components/common/banner"
import Card from "../components/common/card"
import BreadcrumbList from "../components/common/breadcrumb-list"
import ArrowDropdown from "../components/icons/arrow-dropdown"
import { DEPARTMENTS, HONORIFICS, INDUSTRIES, PREFECTURES } from "../constants/constants"
import LinkToUpdate from "../components/common/link-to-update"

const mapStateToProps = (state, ownProps) => {
  return {
    keymansTotal: state.global.keymansTotal,
    corporationsTotal: state.global.corporationsTotal,
    updateKeymans: state.global.updateKeymans.toIndexedSeq().toArray(),
    isLoadingUpdateKeymans: state.global.isLoadingUpdateKeymans,
    keymanCountYstd: state.global.keymanCountYstd,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getKeymansTotal: () => dispatch(actions.getKeymansTotal()),
    getKeymansYesterday: () => dispatch(actions.getKeymansYesterday()),
  }
}

const styles = theme => ({
  container: {
    width: "100%",
    padding: "35px 30px",
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
    justifyContent: "space-between",
    boxSizing: "border-box",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    boxShadow: "0px 0px 10px #254E781A",
    position: "relative",
  },

  col: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "25%",
    boxSizing: "border-box",
    padding: "0 25px",
    marginBottom: "40px",

    [theme.breakpoints.between("xs", "sm")]: {
      width: "50%",
    },

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  titleContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    marginBottom: "40px",
  },

  title: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#181819",
    "&:hover": {
      color: "#5A5B62",
    },
  },

  listItem: {
    fontSize: "14px",
    color: "#181819",
    marginBottom: "14px",
    "&:hover": {
      color: "#5A5B62",
    },
  },
})

class Sitemap extends React.Component {
  componentDidMount() {
    this.props.getKeymansTotal()
    this.props.getKeymansYesterday()
  }

  renderSectionTitle = (title, url) => {
    const { classes } = this.props
    return (
      <div className={classes.titleContainer}>
        <ArrowDropdown
          style={{
            position: "absolute",
            top: "50%",
            left: 0,
            transform: "translate(-100%, -50%) rotate(-90deg)",
          }}
        />
        <Link className={classes.title} to={url}>
          {title}
        </Link>
      </div>
    )
  }

  render() {
    const {
      keymanCountYstd,
      keymansTotal,
      corporationsTotal,
      classes,
    } = this.props

    return (
      <Layout>
        <SEO
          title="サイトマップ"
          description={`サイトマップです。大企業決裁者との商談獲得のためのレター送付ツール「キーマンレター」なら48万人以上の決裁者をリスト化してレターでコンタクトが取れます。`}
          canonical="https://keyman-db.smart-letter.com/sitemap"
        />

        <div className="container">
          <div className="list-container list-margin">
            <main className="left">
              <BreadcrumbList
                list={[{ path: "/", text: "トップ" }]}
                current={{ path: `/sitemap`, text: "サイトマップ" }}
              />
              <h2 className="title">サイトマップ</h2>

              <div className={classes.container}>
                <div className={classes.col}>
                  {this.renderSectionTitle("部門一覧", "/departments")}

                  {DEPARTMENTS.map((item, i) => (
                    <Link
                      key={i}
                      className={classes.listItem}
                      to={`/departments/${item.value}`}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>

                <div className={classes.col}>
                  {this.renderSectionTitle("役職一覧", "/positions")}
                  {HONORIFICS.map((item, i) => (
                    <Link
                      key={i}
                      className={classes.listItem}
                      to={`/positions/${item.value}`}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
                <div className={classes.col}>
                  {this.renderSectionTitle("業界一覧", "/industries")}
                  {INDUSTRIES.map((item, i) => (
                    <Link
                      key={i}
                      className={classes.listItem}
                      to={`/industries/${item.value}`}
                    >
                      {item.value}
                    </Link>
                  ))}
                </div>
                <div className={classes.col}>
                  {this.renderSectionTitle("地域から企業を探す", "/corporations-location-search")}
                  {PREFECTURES.map((item, i) => (
                    <Link
                      key={i}
                      className={classes.listItem}
                      to={`/corporations-location-search/${item.value}`}
                    >
                      {item.value}地方
                    </Link>
                  ))}
                </div>
                <div className={classes.col}>
                  {this.renderSectionTitle("会社一覧", "/corporations")}
                </div>
                <div className={classes.col}>
                  {this.renderSectionTitle("決裁者一覧", "/keymans")}
                </div>
                <div className={classes.col}>
                  {this.renderSectionTitle("昨日更新された決裁者一覧", "/update")}
                </div>
                <div className={classes.col}>
                  {this.renderSectionTitle("インテントデータ検索", "/intent-search")}
                </div>
              </div>
            </main>
            <div className="right">
              <div className="banner">
                <Banner short={true} />
              </div>

              <h2 className="title">昨日更新された決裁者の人数</h2>
              <LinkToUpdate
                keymanCountYstd={keymanCountYstd}
                updateKeymans={this.props.updateKeymans}
                isLoadingUpdateKeymans={this.props.isLoadingUpdateKeymans}
              />
              <Card
                keymanCountYstd={keymanCountYstd}
                keymansTotal={keymansTotal}
                corporationsTotal={corporationsTotal}
                style={{
                  marginBottom: "35px",
                }}
              />
            </div>
          </div>
          <Banner />
        </div>
      </Layout>
    )
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Sitemap)
)
