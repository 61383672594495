import React from "react"
import PropTypes from "prop-types"

const ArrowDropdown = props => {
  const { color, width, height, ...other } = props

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <g
        id="arrow-drop-down"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
        <path
          d="M7.25809674,10.5524739 L11.3657741,14.7029099 C11.7124388,15.0531832 12.2774186,15.0561085 12.6276919,14.7094438 C12.6298812,14.7072771 12.6320592,14.7050991 12.6342258,14.7029099 L16.7419032,10.5524739 C17.0885679,10.2022007 17.0856425,9.6372208 16.7353693,9.29055611 C16.5682997,9.12520776 16.3427354,9.03245937 16.1076773,9.03245937 L7.89232261,9.03245937 C7.39950644,9.03245937 7,9.43196581 7,9.92478198 C7,10.1598401 7.0927484,10.3854044 7.25809674,10.5524739 Z"
          id="Shape"
          fill={color}
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  )
}

ArrowDropdown.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

ArrowDropdown.defaultProps = {
  color: "#000000",
  width: 24,
  height: 24,
}

export default ArrowDropdown
